import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import {
  Hero,
  //SimplfyThings,
  //FeaturedProjects,
  //FeaturedTestimonials,
  //FeaturedArticles,
  //FeaturedServices,
} from "../containers"
//import { BreadCrumb } from "../components"
//import HomeCrumbs from "../constants/BreadCrumbs/HomeCrumbs"

const IndexPage = ({ data }) => {
  /* const {
    allStrapiArticle: { nodes: articles },
    allStrapiProject: { nodes: projects },
    allStrapiTestimonial: { nodes: testimonials },
  } = data */

  const image = getImage(
    data.strapiHomepage.image.localFile.childImageSharp.gatsbyImageData
  )

  const logo = getImage(
    data.strapiHomepage.logo.localFile.childImageSharp.gatsbyImageData
  )

  /*  const selected = crumb => {
    // add or remove breadcrumbs
    console.log(crumb)
  } */

  return (
    <>
      <Hero image={image} logo={logo} />

      <div className=" uk-margin-large-bottom">
        <div className="container">
          <div className=" uk-margin-large-bottom">
            <div className="uk-margin-medium-top uk-margin-medium-bottom">
              <h2 className="gradient-text">Under Maintenance</h2>
            </div>
            <div className="animation uk-margin-medium-top uk-margin-medium-bottom">
              <div className="one spin-one"></div>
              <div className="two spin-two"></div>
              <div className="three spin-one"></div>
            </div>
            {/* <h2 className=" uk-margin-medium-top gradient-text">
              Under maintenance
            </h2> */}
            <div className="uk-margin-small-left uk-margin-small-right uk-margin-medium-bottom uk-text-left">
              <p className="">
                Sorry for the inconvenience. AduDev is currently undergoing
                scheduled maintenance, and major upgrades to include all the
                latest innovations and standards being set within our
                development stack, allowing us to provide our customers the
                purest user experience possible. Click here to
                <a
                  href="https://gatsby-portfolio-adu-dev.netlify.app/contact"
                  alt="Contact me"
                  target="_blank"
                  rel="noreferrer"
                >
                  contact us
                </a>{" "}
                or if you prefer
                <a
                  href="mailto:info@adudev.co.uk"
                  target="_blank"
                  rel="noreferrer"
                >
                  email us
                </a>{" "}
                here Alternatively, please feel free to check out my
                <a
                  href="https://gatsby-portfolio-adu-dev.netlify.app/"
                  alt="AduDev Personal Profile"
                  target="_blank"
                  rel="noreferrer"
                >
                  portfolio
                </a>
                .
              </p>
              {/* <p>
                AduDev Personal Profile
                <a
                  href="https://gatsby-portfolio-adu-dev.netlify.app/"
                  alt="AduDev Personal Profile"
                  target="_blank"
                >
                  Portfolio
                </a>
              </p> */}
              {/* <p>
                To contact me click here!
                <a
                  href="https://gatsby-portfolio-adu-dev.netlify.app/contact"
                  alt="Contact me"
                  target="_blank"
                >
                  Send Message
                </a>
              </p> */}
              {/* <p>
                To email me click here!
                <a href="mailto:info@adudev.co.uk" target="_blank">
                  Email
                </a>
              </p> */}
            </div>

            {/* <p>Update in progress. Please run installer to finish update.</p> */}
          </div>
        </div>
      </div>

      {/* <BreadCrumb crumbs={HomeCrumbs} /> */}

      {/* <FeaturedServices title="Featured Services" /> */}
      {/*  <SimplfyThings /> */}
      {/* <FeaturedProjects projects={projects} title="Featured Projects" /> */}
      {/* <FeaturedTestimonials
        testimonials={testimonials}
        title={"What our clients are saying"}
      /> */}
      {/* <FeaturedArticles
        articles={articles}
        title="Featured Articles"
        styleClass="underlineOrange"
      /> */}
    </>
  )
}

export default IndexPage

export const query = graphql`
  query {
    strapiHomepage {
      hero {
        title
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      logo {
        localFile {
          childImageSharp {
            gatsbyImageData(formats: AUTO, placeholder: BLURRED, height: 50)
          }
        }
      }
    }
    allStrapiArticle(filter: { featured: { eq: true } }) {
      nodes {
        strapiId
        slug
        link
        title
        description
        date
        category {
          name
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 600
                height: 300
                placeholder: BLURRED
                formats: [AUTO]
              )
            }
          }
        }
        author {
          name
          picture {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 50
                  height: 50
                  placeholder: BLURRED
                  formats: [AUTO]
                )
              }
            }
          }
        }
      }
    }
    allStrapiProject(filter: { featured: { eq: true } }) {
      nodes {
        id
        title
        description
        featured
        image {
          id
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: AUTO
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
    allStrapiTestimonial {
      nodes {
        name
        buttonName
        stack
        websiteName
        title
        content
        link
        url
        id
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1200
                height: 450
                placeholder: BLURRED
                formats: [AUTO]
                aspectRatio: 2.1
              )
            }
          }
        }
        avatar {
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: AUTO
                placeholder: BLURRED
                height: 160
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  }
`
